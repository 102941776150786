<template>
  <div :class="{disable:disable}" @click="activeDropDown(true)" class="SelectorContent">
    <select
      :class="{ disabled: selectorValue === ' ',['selector--noBorder']:!border,flag:flags }"
      :data-checkout="checkout"
      :disabled="disable || flags"
      :id="id"
      :required="required"
      @change="validate"
      @click="selectOpen(false,true)"
      ref="selector"
      v-click-outside="selectOpen"
      v-model="selectorValue"
    >
      <option disabled value=" ">{{ placeHolder }}</option>
      <option :key="option.value" :value="option.value" v-for="option in localOptions">{{ option.name }}</option>
    </select>
    <img :src="flagToShop" alt class="flaggy" v-if="flags" />
    <section class="dropDown" v-click-outside="activeDropDown" v-if="dropDownOpen && flags">
      <div :key="Pais.abbreviation" @click="()=>selectedCountry=Pais" v-for="Pais in BanderaPais">
        <img :src="Pais.url" @error="(e)=>e.target.style.display='none'" alt />
        <p>+{{Pais.code}}</p>
      </div>
    </section>
    <i :class="{less:selectRotation==='less' ,more:selectRotation==='more'}" class="icon-mks icon_arrow_select"></i>
  </div>
</template>
<script>
import vClickOutside from 'v-click-outside';
import Banderas from 'country-json/src/country-by-abbreviation.json';
import callingCode from 'country-json/src/country-by-calling-code.json';
export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  // recibe el placeholder del input
  props: {
    initBandera: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String,
      default: ''
    },
    // recibe las opciones con sus respectivos valores EJEMPLO: [
    //     { name: 'nombre1', value: 'valor1' },
    //     { name: 'nombre2', value: 'valor2' },
    //   ]
    options: {
      type: Array,
      default: () => []
    },
    // si se desea que el selector este en alguna opcion predeterminada se debe colocar el value de dicha opcion
    start: {
      type: String,
      default: ''
    },
    // si se desea que el selector tenga algun id para por ejemplo conectarlo a un label
    id: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },

    // setea si se requiere el selector con o sin  borde
    border: {
      type: Boolean,
      default: true
    },
    // data-checkout para mercado pago
    checkout: {
      type: String,
      default: ''
    },
    flags: {
      type: Boolean,
      default: false
    },
    initCode: {
      type: Number,
      default: 57
    },
    indexFlag: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectorValue: ' ',
      selectRotation: 'less',
      dropDownOpen: false,
      BanderaPais: [],
      selectedCountry: {},
      flagToShop: '',
      countrySelected: '',
      localOptions: [...this.options]
    };
  },
  // el evento value devuelve el valor que tiene el select en ese momento de no tener nada seleccionado devolvera un string ' '
  watch: {
    initCode () {
      if (callingCode) {
        this.BanderaPais.forEach((Pais, index) => {
          this.BanderaPais[index].url = Pais.abbreviation ? 'https://api.precompro.com/banderas/' + Pais.abbreviation.toLowerCase() + '.png' : null;
          if (callingCode[index]) {
            this.BanderaPais[index].code = callingCode[index].calling_code;
            if (callingCode[index].calling_code === this.initCode) {
              const selectedCountry = Banderas.filter((pais) => {
                return pais.code === 44;
              });
              this.localOptions = [{ name: '', value: callingCode[index].calling_code }];
              this.selectorValue = callingCode[index].calling_code;
              if (!this.initBandera) {
                this.flagToShop = this.initCode === 44 ? 'https://api.precompro.com/banderas/uk.png' : selectedCountry.url;
              }
            }
          }
        });
      }
    },
    initBandera (bandera) {
      this.flagToShop = bandera;
    },
    selectedCountry (value) {
      this.localOptions = [{ name: '', value: value.code }];
      this.selectorValue = value.code;
      this.flagToShop = value.url;
      this.countrySelected = value.country;
    },
    selectorValue (value, lastThick) {
      this.$emit('value', value);
      if (lastThick !== ' ') {
        if (!this.indexFlag) {
          this.$emit('change', value);
        } else {
          this.$emit('change', { value: value, country: this.countrySelected });
        }
      }
    },
    start () {
      if (this.start) this.selectorValue = this.start;
    },
    options (newVal) {
      this.localOptions = [...newVal];
    }
  },
  methods: {
    activeDropDown (inside) {
      if (!this.disable) {
        if (inside) {
          this.dropDownOpen = !this.dropDownOpen;
        } else {
          this.dropDownOpen = false;
        }
      }
    },
    validate (e = { target: this.$refs.selector }) {
      const selector = e.target;
      if (this.required && this.selectorValue === ' ') {
        selector.setCustomValidity(
          'Debes seleccionar una opcion'
        );
      } else {
        selector.setCustomValidity(
          ''
        );
      }
    },
    selectOpen (close) {
      if (this.selectRotation === 'less' && close === false) {
        this.selectRotation = 'more';
      } else {
        this.selectRotation = 'less';
      }
    }

  },
  mounted () {
    if (this.flags) {
      this.BanderaPais = Banderas;
      if (callingCode) {
        this.BanderaPais.forEach((Pais, index) => {
          this.BanderaPais[index].url = Pais.abbreviation ? 'https://api.precompro.com/banderas/' + Pais.abbreviation.toLowerCase() + '.png' : null;
          if (callingCode[index]) {
            this.BanderaPais[index].code = callingCode[index].calling_code;
            if (callingCode[index].calling_code === this.initCode) {
              this.localOptions = [{ name: '', value: this.BanderaPais[index].code }];
              this.selectorValue = this.BanderaPais[index].code;
              this.flagToShop = this.initBandera;
            }
          }
        });
      }
    }

    if (this.start) {
      this.selectorValue = this.start;
    }
    this.validate();
  }
};
</script>
<style lang="scss" scoped>
.flaggy {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 5px;
  margin: auto;
  cursor: pointer;
  background-color: transparent;
}
.disable .flaggy {
  cursor: not-allowed;
}
.dropDown {
  position: absolute;
  bottom: -160px;
  left: 5px;
  background-color: white;
  max-height: 150px;
  width: 100px;
  overflow: scroll;
  z-index: 100000;
  div {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding: 3px 0;
    gap: 8px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 174, 255, 0.726);
    }
  }
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 3px;
  }
  .replacement {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 3px;
    background-color: green;
  }
}
select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  outline: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}
.SelectorContent {
  background-color: transparent;
  font-size: 12px;
  display: flex;
  width: auto;
  text-indent: 1rem;
  border-radius: 4px;
  height: fit-content;
  min-width: 35px;
  position: relative;
  outline: unset;
  select {
    width: 100%;
    padding-left: 15px;
    padding-right: 28px;
    height: 30px;
    border: #444b57 1px solid;
    border-radius: 4px;
    color: black;
    outline: unset;
    color: #444b57;
    background-color: transparent;
  }
  .selector--noBorder {
    border: none;
    padding-left: 10px;
  }
  select:focus ~ span {
    color: black;
  }
  .icon_arrow_select{
    width: 10px;
    height: 6px;
    position: absolute;
    top: 13px;
    right: 11px;
    margin: 0;
  }
  span {
    position: absolute;
    right: 0.5rem;
    top: 14px;
    font-size: 1.4rem;
    margin: auto;
    width: 10px;
    height: fit-content;
    color: black;
    pointer-events: none;
  }
  .arrow {
    background-image: url("../../assets/images/icons/select/selectorArrowHelper.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 6px;
    display: block;
    right: 12px;
  }
  .flag {
    text-indent: 5px;
  }
  .more {
    transform: rotate(180deg);
  }
}
.selector--noBorder {
  border: unset;
}

option:disabled {
  color: #757575;
}
option:not(:disabled) {
  color: black;
  text-indent: 1rem;
}
.disable {
  cursor: not-allowed;
}
</style>
